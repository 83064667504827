.App {
  padding: 2em;
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.userInputContainer {
  display: flex;
  flex-direction: column;
}

.playersLabelDesc {
  margin-top: 0;
  color: red;
}

.generateBtn {
  width: fit-content;
  margin-top: 1em;
}

.generateBtn:hover {
  cursor: pointer;
}

.inputRule {
  font-size: 12px;
}

.logInBtn {
  margin-bottom: 1em;
}
